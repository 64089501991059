// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-item-styles__menuItemBorder--qvUYB {
  border-bottom: 1px solid var(--cps-color-silver);
}

.menu-item-styles__menuItemHeight--WLiTw {
  height: 46px;
  min-height: 46px;
}

.menu-item-styles__menuItem--AYZZ1 {
  text-decoration: none;
  color: var(--cps-color-primary-text);
  outline: none;
}

.menu-item-styles__menuItem--AYZZ1:hover,
.menu-item-styles__menuItem--AYZZ1:focus {
  text-decoration: none;
  outline: none;
}

.menu-item-styles__menuItem--AYZZ1:hover {
  background-color: var(--cps-color-ash);
}
`, "",{"version":3,"sources":["webpack://./src/common/menu-item.styles.css"],"names":[],"mappings":"AAAA;EACE,gDAAgD;AAClD;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EAGE,qBAAqB;EACrB,oCAAoC;EACpC,aAAa;AACf;;AAEA;;EAEE,qBAAqB;EACrB,aAAa;AACf;;AAEA;EACE,sCAAsC;AACxC","sourcesContent":[".menuItemBorder {\n  border-bottom: 1px solid var(--cps-color-silver);\n}\n\n.menuItemHeight {\n  height: 46px;\n  min-height: 46px;\n}\n\n.menuItem {\n  composes: menuItemBorder;\n  composes: menuItemHeight;\n  text-decoration: none;\n  color: var(--cps-color-primary-text);\n  outline: none;\n}\n\n.menuItem:hover,\n.menuItem:focus {\n  text-decoration: none;\n  outline: none;\n}\n\n.menuItem:hover {\n  background-color: var(--cps-color-ash);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menuItemBorder": `menu-item-styles__menuItemBorder--qvUYB`,
	"menuItemHeight": `menu-item-styles__menuItemHeight--WLiTw`,
	"menuItem": `menu-item-styles__menuItem--AYZZ1 menu-item-styles__menuItemBorder--qvUYB menu-item-styles__menuItemHeight--WLiTw`
};
export default ___CSS_LOADER_EXPORT___;
