// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../common/menu-item.styles.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.service-steps-styles__root--u_WWw {
  padding: 16px 24px 11px 24px;
}

.service-steps-styles__step--eLGwl {
  height: 27px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.service-steps-styles__stepName--f7O4d {
  text-decoration: none;
  color: var(--cps-color-primary-text);
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 4px;
}
.service-steps-styles__stepName--f7O4d:hover,
.service-steps-styles__stepName--f7O4d:focus {
  text-decoration: none;
}

.service-steps-styles__has2Icons--JAwmi {
  min-width: 48px;
}

.service-steps-styles__has3Icons--MPkhB {
  min-width: 72px;
}

.service-steps-styles__addStep--hX42l {
  margin-left: -12px !important;
  color: var(--cps-color-primary-text) !important;
}

.service-steps-styles__stepActions--wKMWC {
  display: none;
}

.service-steps-styles__step--eLGwl:hover .service-steps-styles__stepActions--wKMWC {
  display: flex;
  flex-direction: row;
}

.service-steps-styles__active--zsA5f {
  font-weight: bold;
}

.service-steps-styles__needs_review--E9Her {
  color: var(--cps-color-warning);
}

.service-steps-styles__complete--IFeSq {
  color: var(--cps-color-success);
}
`, "",{"version":3,"sources":["webpack://./src/workflow/service-steps.styles.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;AAE9B;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,qBAAqB;EACrB,oCAAoC;EACpC,WAAW;EACX,eAAe;EACf,gBAAgB;EAChB,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;AACpB;AACA;;EAEE,qBAAqB;AACvB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,6BAA6B;EAC7B,+CAA+C;AACjD;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,+BAA+B;AACjC","sourcesContent":[".root {\n  padding: 16px 24px 11px 24px;\n  composes: menuItemBorder from \"../common/menu-item.styles.css\";\n}\n\n.step {\n  height: 27px;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n  width: 100%;\n}\n\n.stepName {\n  text-decoration: none;\n  color: var(--cps-color-primary-text);\n  width: 100%;\n  max-width: 100%;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  padding-right: 4px;\n}\n.stepName:hover,\n.stepName:focus {\n  text-decoration: none;\n}\n\n.has2Icons {\n  min-width: 48px;\n}\n\n.has3Icons {\n  min-width: 72px;\n}\n\n.addStep {\n  margin-left: -12px !important;\n  color: var(--cps-color-primary-text) !important;\n}\n\n.stepActions {\n  display: none;\n}\n\n.step:hover .stepActions {\n  display: flex;\n  flex-direction: row;\n}\n\n.active {\n  font-weight: bold;\n}\n\n.needs_review {\n  color: var(--cps-color-warning);\n}\n\n.complete {\n  color: var(--cps-color-success);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `service-steps-styles__root--u_WWw ${___CSS_LOADER_ICSS_IMPORT_0___.locals["menuItemBorder"]}`,
	"step": `service-steps-styles__step--eLGwl`,
	"stepName": `service-steps-styles__stepName--f7O4d`,
	"has2Icons": `service-steps-styles__has2Icons--JAwmi`,
	"has3Icons": `service-steps-styles__has3Icons--MPkhB`,
	"addStep": `service-steps-styles__addStep--hX42l`,
	"stepActions": `service-steps-styles__stepActions--wKMWC`,
	"active": `service-steps-styles__active--zsA5f`,
	"needs_review": `service-steps-styles__needs_review--E9Her`,
	"complete": `service-steps-styles__complete--IFeSq`
};
export default ___CSS_LOADER_EXPORT___;
