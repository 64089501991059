import React from "react";
import { catchAsyncStacktrace } from "auto-trace";
import { capitalize } from "lodash";
import Cancelable from "react-disposable-decorator";

import { createNewSourceFormMultiple } from "./source-form-multiples.resources.js";
import { patchResolutionCaseAnswer } from "./resolution-case-answers.resource.js";
import { getSourceFormGroups } from "./source-form-groups.resource.js";
import { updateActiveSourceForm } from "./with-active-source-form.component.js";

@Cancelable
export default class FetchSourceForms extends React.Component {
  constructor() {
    super();
    this.state = {
      sourceFormGroups: null,
    };
  }
  componentDidMount() {
    const javaTaxpayerType = convertPHPTaxpayerTypeToJavaTaxpayerType(
      this.props.resolutionCase.taxpayer_type
    );

    this.props.cancelWhenUnmounted(
      patchResolutionCaseAnswer(
        this.props.clientId,
        this.props.resolutionCaseId,
        this.props.smeVersion,
        this.props.smeRevision,
        "TaxpayerType",
        javaTaxpayerType
      ).subscribe(
        (answerResp) =>
          this.setState({
            sourceFormGroups: answerResp.groups,
          }),
        catchAsyncStacktrace()
      )
    );

    SystemJS.import("end-user-forms-ui!sofe").then((formsUi) => {
      this.props.cancelWhenUnmounted(
        formsUi.answerAsObservable.subscribe((answerResp) =>
          updateActiveSourceForm(answerResp.sourceFormLayout)
        )
      );
    }, catchAsyncStacktrace());
  }

  componentDidUpdate(prevProps) {
    const revisionChanged =
      this.props.smeRevision &&
      prevProps.smeRevision &&
      prevProps.smeRevision !== this.props.smeRevision;
    const versionChanged =
      this.props.smeVersion &&
      prevProps.smeVersion &&
      prevProps.smeVersion !== this.props.smeVersion;
    if (versionChanged || revisionChanged) {
      getSourceFormGroups(
        this.props.clientId,
        this.props.resolutionCaseId,
        this.props.smeVersion.id,
        this.props.smeRevision.id
      ).subscribe(
        (sourceFormGroups) => this.setState({ sourceFormGroups }),
        catchAsyncStacktrace()
      );
    }
  }

  render() {
    return this.props.children({
      sourceFormGroups: this.state.sourceFormGroups,
      addFormMultiple: this.addFormMultiple,
    });
  }

  addFormMultiple = (formId, index) => {
    createNewSourceFormMultiple(
      this.props.clientId,
      this.props.resolutionCaseId,
      this.props.smeVersion.id,
      this.props.smeRevision.id,
      formId,
      index
    ).subscribe(({ groups, sourceFormLayout }) => {
      this.setState({ sourceFormGroups: groups });
      updateActiveSourceForm(sourceFormLayout);
    }, catchAsyncStacktrace());
  };
}

/* Unfortunately, for a long time resolution cases have called the taxpayer type either 'individual' or 'business',
 * but our SMEs have been writing rules based on a taxpayer type of either 'Individual' or 'Business'.
 * And since the front end is the only glue between resolution cases and the Form Service, it's our solemn
 * duty to convert from one to the other.
 */
export function convertPHPTaxpayerTypeToJavaTaxpayerType(taxpayerType) {
  return capitalize(taxpayerType);
}
