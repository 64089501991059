import fetcher, { fetchWithSharedCache, fetchAsObservable } from "fetcher!sofe";
import { pluck } from "rxjs/operators";
import canopyUrls from "canopy-urls!sofe";
import { clone } from "lodash";

export function getResolutionCaseObservable(clientId, resolutionCaseId) {
  if (!clientId)
    throw new Error(
      `Cannot get resolution case without being provided a clientId`
    );
  if (!resolutionCaseId)
    throw new Error(
      `Cannot get resolution case without being provided an resolutionCaseId`
    );

  return fetchWithSharedCache(
    `/api/clients/${clientId}/resolution_cases/${resolutionCaseId}`,
    `/resolution_cases/${resolutionCaseId}`
  ).pipe(pluck("resolution_cases"));
}

export function putResolutionCase(clientId, resolutionCaseId, resolutionCase) {
  if (!clientId) throw new Error(`clientId is required. Was '${clientId}'`);
  if (!resolutionCaseId)
    throw new Error(`resolutionCaseId is required. Was '${resolutionCaseId}'`);
  if (!resolutionCase)
    throw new Error(`resolutionCase is required. Was '${resolutionCase}'`);
  const resolutionCases = clone(resolutionCase);
  // archived_at is not supported by this endpoint anymore, is_archived is used instead
  // but some of the old data returned still returns archived_at from the GET. So we delete this to avoid a 400
  delete resolutionCases.archived_at;

  return fetcher(
    `/api/clients/${clientId}/resolution_cases/${resolutionCaseId}`,
    {
      method: "PUT",
      body: { resolution_cases: resolutionCases },
      headers: {
        "API-Version": "v1",
      },
    }
  ).then((response) => {
    if (response.ok) {
      return response.json().then((json) => {
        return json.resolution_cases;
      });
    } else {
      throw new Error(
        `Could not put resolution case -- API returned with status ${response.status}`
      );
    }
  });
}

export function getClient(clientId) {
  return fetchAsObservable(`/api/clients/${clientId}?include=users`).pipe(
    pluck("clients")
  );
}
