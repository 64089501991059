// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fixed-menu-styles__root--H6ZVD {
  width: 322px;
  position: fixed;
  background-color: var(--cps-color-bumble);
  color: var(--cps-color-primary-text);
  height: 100%;
  z-index: 97;
  border-right: 1px solid var(--cps-color-silver);
  left: 8rem;
}
`, "",{"version":3,"sources":["webpack://./src/common/fixed-menu.styles.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,eAAe;EACf,yCAAyC;EACzC,oCAAoC;EACpC,YAAY;EACZ,WAAW;EACX,+CAA+C;EAC/C,UAAU;AACZ","sourcesContent":[".root {\n  width: 322px;\n  position: fixed;\n  background-color: var(--cps-color-bumble);\n  color: var(--cps-color-primary-text);\n  height: 100%;\n  z-index: 97;\n  border-right: 1px solid var(--cps-color-silver);\n  left: 8rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `fixed-menu-styles__root--H6ZVD`
};
export default ___CSS_LOADER_EXPORT___;
