// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../common/menu-item.styles.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.workflow-forms-switcher-styles__root--nTVhh {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.workflow-forms-switcher-styles__tab--dRBVa {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: var(--cps-color-primary-text);
  text-decoration: none;
  width: 100%;
  height: 100%;
}

.workflow-forms-switcher-styles__tab--dRBVa:hover,
.workflow-forms-switcher-styles__tab--dRBVa:focus {
  text-decoration: none;
}

.workflow-forms-switcher-styles__active--QRJy0 {
  font-weight: 600;
  border-bottom: 2px solid var(--cps-color-primary);
}
`, "",{"version":3,"sources":["webpack://./src/header/workflow-forms-switcher.styles.css"],"names":[],"mappings":"AAAA;EAGE,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,oCAAoC;EACpC,qBAAqB;EACrB,WAAW;EACX,YAAY;AACd;;AAEA;;EAEE,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;EAChB,iDAAiD;AACnD","sourcesContent":[".root {\n  composes: menuItemHeight from \"../common/menu-item.styles.css\";\n  composes: menuItemBorder from \"../common/menu-item.styles.css\";\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n}\n\n.tab {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  cursor: pointer;\n  color: var(--cps-color-primary-text);\n  text-decoration: none;\n  width: 100%;\n  height: 100%;\n}\n\n.tab:hover,\n.tab:focus {\n  text-decoration: none;\n}\n\n.active {\n  font-weight: 600;\n  border-bottom: 2px solid var(--cps-color-primary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `workflow-forms-switcher-styles__root--nTVhh ${___CSS_LOADER_ICSS_IMPORT_0___.locals["menuItemHeight"]} ${___CSS_LOADER_ICSS_IMPORT_0___.locals["menuItemBorder"]}`,
	"tab": `workflow-forms-switcher-styles__tab--dRBVa`,
	"active": `workflow-forms-switcher-styles__active--QRJy0`
};
export default ___CSS_LOADER_EXPORT___;
