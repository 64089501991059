import React from "react";
import { ReplaySubject } from "rxjs";
import { catchAsyncStacktrace } from "auto-trace";

const sourceFormSubject = new ReplaySubject(1);

export function updateActiveSourceForm(newSourceFormLayout) {
  sourceFormSubject.next(newSourceFormLayout);
}

export default class WithActiveSourceForm extends React.Component {
  constructor() {
    super();
    this.state = {
      activeSourceForm: null,
    };
  }
  componentDidMount() {
    this.subscription = sourceFormSubject.subscribe(
      (activeSourceForm) => this.setState({ activeSourceForm }),
      catchAsyncStacktrace()
    );
  }
  render() {
    return (
      <div data-testid={this.props["data-testid"]}>
        {this.props.children({ activeSourceForm: this.state.activeSourceForm })}
      </div>
    );
  }
  componentWillUnmount() {
    this.subscription.unsubscribe();
  }
}
