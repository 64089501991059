import React from "react";
import ResolutionCaseTitle from "./resolution-case-title.component.js";
import Search from "./search.component.js";
import WorkflowFormsSwitcher from "./workflow-forms-switcher.component.js";
import ToggleSMEVersion from "./toggle-sme-version.component.js";
import { get } from "lodash";

export default class Header extends React.Component {
  render() {
    const {
      resolutionCase,
      newResolutionCaseTitleHeight,
      newSearchString,
      activeTab,
      setActiveTab,
      searchString,
    } = this.props;
    return (
      <div>
        <ToggleSMEVersion resolutionCaseType={resolutionCase.forms_version} />
        <ResolutionCaseTitle
          resolutionCase={resolutionCase}
          newResolutionCaseTitleHeight={newResolutionCaseTitleHeight}
        />
        <Search newSearchString={newSearchString} />
        {!searchString && (
          <WorkflowFormsSwitcher
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        )}
      </div>
    );
  }
}
