import { fetchAsObservable } from "fetcher!sofe";
import { pluck } from "rxjs/operators";

export function patchResolutionCaseAnswer(
  clientId,
  resolutionCaseId,
  smeVersion,
  smeRevision,
  answerName,
  answerValue
) {
  if (!clientId)
    throw new Error(`clientId is required, but ${clientId} was given`);
  if (!resolutionCaseId)
    throw new Error(
      `resolutionCaseId is required, but ${resolutionCaseId} was given`
    );
  if (!smeVersion)
    throw new Error(`smeVersion is required, but ${smeVersion} was given`);
  if (!smeRevision)
    throw new Error(`smeRevision is required, but ${smeRevision} was given`);
  if (!answerName)
    throw new Error(`answerName is required, but ${answerName} was given`);
  if (!answerValue)
    throw new Error(`answerValue is required, but ${answerValue} was given`);

  const answers = { [answerName]: answerValue };

  return fetchAsObservable(
    `/clients/${clientId}/resolution_cases/${resolutionCaseId}/versions/${smeVersion.id}-${smeRevision.id}/answers`,
    {
      method: "POST",
      body: {
        answers,
      },
    }
  ).pipe(pluck("answers"));
}
