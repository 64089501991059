import React from "react";
import MenuLink from "../common/menu-link.component.js";
import ServiceSteps from "./service-steps.component.js";
import { stringMatchesSearch } from "../common/search.utils.js";
import { property } from "lodash";

const taxResServiceSlugs = [
  "collections",
  "innocent_spouse",
  "liens",
  "levies",
  "trust_fund_recovery",
  "penalty_abatement",
];

export default function Services(props) {
  const {
    resolutionCase,
    toggleServiceExpanded,
    expandedServices,
    clientId,
    resolutionCaseId,
    searchString,
    hasAccess,
  } = props;

  return (
    <div>
      {resolutionCase.program_data.programs
        .filter(property("visible"))
        .filter((program) => {
          if (taxResServiceSlugs.includes(program.slug))
            return hasAccess("tax_resolution_services");
          else return hasAccess("custom_services");
        })
        .map((program) => {
          const programMatchesSearch =
            !!searchString && stringMatchesSearch(program.name, searchString);
          const stepsMatchSearch =
            !!searchString &&
            program.sections.some((section) =>
              stringMatchesSearch(section.name, searchString)
            );
          const isExpanded =
            programMatchesSearch ||
            stepsMatchSearch ||
            expandedServices.indexOf(program.slug) >= 0;
          return (
            <div key={program.slug}>
              <MenuLink
                icon="cps-icon-work"
                text={program.name}
                href={null}
                onClick={(e) => {
                  e.preventDefault();
                  toggleServiceExpanded(program.slug);
                }}
                searchString={searchString}
                hideIfNotSearched={!stepsMatchSearch}
              />
              {isExpanded && (
                <ServiceSteps
                  key={program.slug}
                  program={program}
                  clientId={clientId}
                  resolutionCaseId={resolutionCaseId}
                  resolutionCase={resolutionCase}
                  searchString={searchString}
                />
              )}
            </div>
          );
        })}
    </div>
  );
}
