import React from "react";
import MenuLink from "../common/menu-link.component.js";
import Services from "./services.component.js";
import { hasAccess } from "cp-client-auth!sofe";
import { getClient } from "../common/resolution_cases.resource.js";

export default class WorkflowTab extends React.Component {
  constructor() {
    super();
    this.state = {
      client: {},
    };
  }

  componentDidMount() {
    this.clientSub = getClient(this.props.clientId).subscribe((res) => {
      this.setState({ client: res });
    });
  }

  componentWillUnmount() {
    this.clientSub.unsubscribe();
  }

  render() {
    const {
      clientId,
      resolutionCaseId,
      resolutionCase,
      searchString,
      expandedServices,
      setExpandedServices,
    } = this.props;
    const { client } = this.state;
    const userHasAccess = hasAccess(window.loggedInUser);
    const lettersLink = `#/taxes/client/${clientId}/resolution-cases/${resolutionCaseId}/letter-generator`;

    return (
      <div data-testid="workflow-tab">
        <MenuLink
          icon="cps-icon-reports"
          text="Summary"
          link={`#/taxes/client/${clientId}/resolution-cases/${resolutionCaseId}/summary`}
          searchString={searchString}
          hideIfNotSearched={true}
        />
        {userHasAccess("letters") && client.is_active && (
          <MenuLink
            icon="cps-icon-letters"
            text="Letters"
            link={lettersLink}
            searchString={searchString}
            hideIfNotSearched={true}
          />
        )}
        {userHasAccess("tasks_client_requests") && (
          <MenuLink
            icon="cps-icon-transfer"
            text="Client requests"
            link={`#/taxes/client/${clientId}/resolution-cases/${resolutionCaseId}/client_requests`}
            searchString={searchString}
            hideIfNotSearched={true}
          />
        )}
        {userHasAccess("client_survey") && (
          <MenuLink
            icon="cps-icon-survey"
            text="Client survey"
            link={`#/forms/clients/${clientId}/resolution-cases/${resolutionCaseId}/survey`}
            searchString={searchString}
            hideIfNotSearched={true}
          />
        )}
        <Services
          clientId={clientId}
          hasAccess={userHasAccess}
          resolutionCaseId={resolutionCaseId}
          resolutionCase={resolutionCase}
          toggleServiceExpanded={this.toggleServiceExpanded}
          expandedServices={expandedServices}
          searchString={searchString}
        />
        <MenuLink
          icon="cps-icon-lock"
          text="Archive"
          link={`#/taxes/client/${clientId}/resolution-cases/${resolutionCaseId}/lock`}
          searchString={searchString}
          hideIfNotSearched={true}
        />
        <MenuLink
          icon="cps-icon-reorder"
          text="Services"
          link={`#/taxes/client/${clientId}/resolution-cases/${resolutionCaseId}/services`}
          searchString={searchString}
          hideIfNotSearched={true}
        />
      </div>
    );
  }

  toggleServiceExpanded = (slug) => {
    if (this.props.expandedServices.indexOf(slug) >= 0) {
      this.props.setExpandedServices(
        this.props.expandedServices.filter((service) => service !== slug)
      );
    } else {
      this.props.setExpandedServices(this.props.expandedServices.concat(slug));
    }
  };
}
