import React from "react";
import styles from "./fixed-menu.styles.css";
import { NavContent, primaryNavHeightObs } from "primary-navbar!sofe";
import { clientMenuHeightObs } from "client-menu!sofe";
import { catchAsyncStacktrace } from "auto-trace";

export default class FixedMenu extends React.Component {
  constructor() {
    super();
    this.state = {
      primaryNavHeight: 0,
      clientMenuHeight: 0,
    };
    this.disposables = [];
  }
  componentDidMount() {
    this.disposables.concat(
      primaryNavHeightObs.subscribe(
        (height) => this.setState({ primaryNavHeight: height }),
        catchAsyncStacktrace()
      ),

      clientMenuHeightObs.subscribe(
        (height) => this.setState({ clientMenuHeight: height }),
        catchAsyncStacktrace()
      )
    );
  }
  render() {
    const heightAboveResolutionCasesMenu =
      this.state.primaryNavHeight + this.state.clientMenuHeight;
    const height = `calc(100% - ${heightAboveResolutionCasesMenu}px)`;
    return (
      <NavContent
        topStyleAttribute="top"
        useNavContentClass={false}
        clientMenuPossible={true}
        hasTopnavSecondary={false}
        className={styles.root}
        style={{ height, maxHeight: height }}
      >
        {this.props.children}
      </NavContent>
    );
  }
  componentWillUnmount() {
    this.disposables.forEach((disposable) => disposable.unsubscribe());
  }
}
