import React from "react";
import { infoToast } from "toast-service!sofe";
import { UserTenantProps } from "cp-client-auth!sofe";

@UserTenantProps({ permissions: { hasSmePermissions: "sme" } })
export default class IApologizeForTrying extends React.Component {
  componentDidMount() {
    const { message, permissions = {} } = this.props;
    if (permissions.hasSmePermissions) {
      infoToast(message, "I apologize for trying", null, 150000);
    } else {
      throw new Error(message);
    }
  }
  render() {
    return null;
  }
}
