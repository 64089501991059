import React from "react";
import { isEmpty } from "lodash";
import {
  MAX_TITLE_LENGTH,
  MAX_DESCRIPTION_LENGTH,
} from "./section.constants.js";
import { createSection } from "./program-sections.resource.js";
import { catchError } from "auto-trace";
import { CpButton, CpModal, CpInput } from "canopy-styleguide!sofe";

const defaultState = {
  title: "",
  description: "",
  saving: false,
};

export default class AddStep extends React.Component {
  constructor() {
    super();
    this.state = defaultState;
  }

  componentDidMount() {
    this.titleRef?.focus();
  }

  addStep = () => {
    this.setState({ saving: true });

    createSection(
      this.props.clientId,
      this.props.resolutionCaseId,
      this.props.program.uid,
      this.state.title,
      this.state.description
    ).subscribe(
      (section) => {
        this.props.onClose();
        this.props.program.sections.push(section);
        window.location.href = `#/taxes/client/${this.props.clientId}/resolution-cases/${this.props.resolutionCaseId}/program/${this.props.program.slug}/section/${section.id}`;
        this.setState(defaultState);
      },
      catchError((err, throwError) => {
        this.setState({ saving: false });
        throwError(err);
      })
    );
  };

  render() {
    return (
      <CpModal
        show={this.props.show}
        onClose={() => {
          this.props.onClose();
          this.setState(defaultState);
        }}
      >
        <CpModal.Header title="Add New Steps" />
        <CpModal.Body>
          <CpInput
            label="Step title"
            className="cp-mb-16"
            value={this.state.title}
            required={true}
            maxLength={MAX_TITLE_LENGTH}
            onChange={(title) => this.setState({ title })}
          />
          <CpInput
            label="Step description"
            className="cp-mb-16"
            value={this.state.description}
            required={true}
            maxLength={MAX_DESCRIPTION_LENGTH}
            onChange={(description) => this.setState({ description })}
          />
        </CpModal.Body>
        <CpModal.Footer>
          <CpButton
            btnType="primary"
            disabled={
              isEmpty(this.state.title) || isEmpty(this.state.description)
            }
            disableOnClick={true}
            onClick={this.addStep}
            showLoader={this.state.saving}
          >
            Add step
          </CpButton>
          <CpButton
            btnType="flat"
            onClick={() => {
              this.props.onClose();
              this.setState(defaultState);
            }}
            className="cp-ml-8"
            disabled={this.state.saving}
          >
            Close
          </CpButton>
        </CpModal.Footer>
      </CpModal>
    );
  }
}
