import React from "react";
import FixedMenu from "./common/fixed-menu.component.js";
import WithUrlParams from "./common/with-url-params.component.js";
import FetchResolutionCase from "./common/fetch-resolution-case.component.js";
import Menu from "./menu.component.js";
import { UserTenantProps } from "cp-client-auth!sofe";

@UserTenantProps({
  permissions: {
    hasResolutionCases: "tasks_resolution_cases",
  },
  waitForData: true,
})
export default class ResolutionCasesMenuRoot extends React.Component {
  render() {
    if (this.props.permissions && !this.props.permissions.hasResolutionCases) {
      console.info(
        `The loggedInUser does not have "tasks_resolution_cases", redirecting from resolution-cases-menu`
      );
      window.history.replaceState(null, "", `/#/403`);
    }
    return (
      <FixedMenu>
        <WithUrlParams>
          {({ clientId, resolutionCaseId, newUrlParams }) => (
            <FetchResolutionCase
              clientId={clientId}
              resolutionCaseId={resolutionCaseId}
              newUrlParams={newUrlParams}
            >
              {({ resolutionCase }) =>
                resolutionCase && (
                  <Menu
                    clientId={clientId}
                    resolutionCaseId={resolutionCaseId}
                    newUrlParams={newUrlParams}
                    resolutionCase={resolutionCase}
                  />
                )
              }
            </FetchResolutionCase>
          )}
        </WithUrlParams>
      </FixedMenu>
    );
  }
}
