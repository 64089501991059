import {
  MAX_TITLE_LENGTH,
  MAX_DESCRIPTION_LENGTH,
} from "./section.constants.js";
import canopyUrls from "canopy-urls!sofe";
import fetcher, { forceBustCache, fetchAsObservable } from "fetcher!sofe";
import { pluck, mergeMap } from "rxjs/operators";
import { from } from "rxjs";

export function createSection(
  clientId,
  resolutionCaseId,
  programId,
  title,
  description
) {
  if (!clientId) throw new Error(`Must provide client id`);
  if (!resolutionCaseId) throw new Error(`Must provide resolutionCaseId`);

  const section = newSection(title, description, resolutionCaseId, programId);

  return fetchAsObservable(
    `/api/clients/${clientId}/resolution_cases/${resolutionCaseId}/sections`,
    {
      method: "post",
      body: { resolution_case_sections: section },
      headers: {
        "API-Version": "v1",
      },
    }
  ).pipe(
    pluck("resolution_case_sections"),
    mergeMap((json) => {
      section.id = json.id;
      section.slug = Number(section.id).toString();
      return from(
        forceBustCache(
          `/api/clients/${clientId}/resolution_cases/${resolutionCaseId}`
        ).then(() => section)
      );
    })
  );
}

export function deleteSection(clientId, resolutionCaseId, sectionId) {
  if (!clientId) {
    throw new Error(`Must provide client id`);
  }
  if (!resolutionCaseId) {
    throw new Error(`Must provide resolutionCaseId`);
  }
  if (!sectionId) {
    throw new Error(`Must provide section id`);
  }

  return fetcher(
    `/api/clients/${clientId}/resolution_cases/${resolutionCaseId}/sections/${sectionId}`,
    {
      method: "DELETE",
      headers: {
        "API-Version": "v1",
      },
    }
  ).then((response) => {
    if (response.ok) {
      return forceBustCache(
        `/api/clients/${clientId}/resolution_cases/${resolutionCaseId}`
      );
    } else {
      throw new Error(
        `Could not delete section. HTTP status was ${response.status}`
      );
    }
  });
}

function newSection(name, description, resolutionCaseId, programId) {
  if (!resolutionCaseId) {
    throw new Error(`Must provide resolutionCaseId`);
  }
  if (!programId) {
    throw new Error(`Must provide program id`);
  }
  if (typeof name !== "string" || name.length > MAX_TITLE_LENGTH) {
    throw new Error(
      `'name' must be a string of max length ${MAX_TITLE_LENGTH}`
    );
  }

  if (
    typeof description !== "string" ||
    description.length > MAX_DESCRIPTION_LENGTH
  ) {
    throw new Error(
      `'description' must be a string of max length ${MAX_DESCRIPTION_LENGTH}`
    );
  }

  return {
    name: name,
    description: description,
    is_removable: true,
    status: "in_progress",
    relationships: {
      parent_resolution_case: {
        type: "resolution_case",
        id: Number(resolutionCaseId),
      },
      parent_program: {
        type: "resolution_case_program",
        uid: programId,
      },
    },
  };
}
