// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../common/menu-item.styles.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-styles__root--VjjWn {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 24px;
  cursor: pointer;
}

.search-styles__icon--lwRAm {
  margin-right: 8px;
  color: var(--cps-color-secondary-text);
}

.search-styles__input--o_tT7 {
  border: none;
  outline: none;
  font-size: 14px;
  width: 100%;
  margin-right: 24px;
}

input::placeholder {
  color: var(--cps-color-input-placeholder);
  opacity: 1; /* for firefox */
}

.search-styles__input--o_tT7:focus,
input:hover {
  outline: none;
}
`, "",{"version":3,"sources":["webpack://./src/header/search.styles.css"],"names":[],"mappings":"AAAA;EAGE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,mBAAmB;EACnB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,sCAAsC;AACxC;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,eAAe;EACf,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,yCAAyC;EACzC,UAAU,EAAE,gBAAgB;AAC9B;;AAEA;;EAEE,aAAa;AACf","sourcesContent":[".root {\n  composes: menuItemBorder from \"../common/menu-item.styles.css\";\n  composes: menuItemHeight from \"../common/menu-item.styles.css\";\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: center;\n  padding-left: 24px;\n  cursor: pointer;\n}\n\n.icon {\n  margin-right: 8px;\n  color: var(--cps-color-secondary-text);\n}\n\n.input {\n  border: none;\n  outline: none;\n  font-size: 14px;\n  width: 100%;\n  margin-right: 24px;\n}\n\ninput::placeholder {\n  color: var(--cps-color-input-placeholder);\n  opacity: 1; /* for firefox */\n}\n\n.input:focus,\ninput:hover {\n  outline: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `search-styles__root--VjjWn ${___CSS_LOADER_ICSS_IMPORT_0___.locals["menuItemBorder"]} ${___CSS_LOADER_ICSS_IMPORT_0___.locals["menuItemHeight"]}`,
	"icon": `search-styles__icon--lwRAm`,
	"input": `search-styles__input--o_tT7`
};
export default ___CSS_LOADER_EXPORT___;
