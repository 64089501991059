import React from "react";
import { CpButton, CpModal } from "canopy-styleguide!sofe";

import { deleteSection } from "./program-sections.resource.js";
import { catchError } from "auto-trace";

export default class DeleteStep extends React.Component {
  constructor() {
    super();
    this.state = {
      deleting: false,
    };
  }

  deleteStep = () => {
    this.setState({ deleting: true });

    const { clientId, resolutionCaseId, stepInfo, program, onClose } =
      this.props;

    deleteSection(clientId, resolutionCaseId, stepInfo.id)
      .then(() => {
        program.sections = program.sections.filter((section) => {
          return section.id !== stepInfo.id;
        });

        onClose();
        this.setState({ deleting: false });

        if (window.location.href.indexOf(`/section/${stepInfo.id}`) >= 0) {
          window.location.href = `#/taxes/client/${clientId}/resolution-cases/${resolutionCaseId}/summary`;
        }
      })
      .catch((err) => {
        this.setState({ deleting: false });
        catchError(err);
      });
  };

  render() {
    const { onClose, stepInfo } = this.props;
    return (
      <CpModal show={this.props.show} onClose={this.props.onClose}>
        <CpModal.Header title="Delete Step" />
        <CpModal.Body>
          You're about to delete the step "{stepInfo.name}". All the files and
          comments associated with this step will be deleted. This cannot be
          undone.
        </CpModal.Body>
        <CpModal.Footer>
          <CpButton
            btnType="primary"
            onClick={this.deleteStep}
            disableOnClick={true}
            showLoader={this.state.deleting}
          >
            Delete step
          </CpButton>
          <CpButton btnType="flat" onClick={onClose} className="cp-ml-8">
            Close
          </CpButton>
        </CpModal.Footer>
      </CpModal>
    );
  }
}
