import React from "react";
import MenuLink from "../common/menu-link.component.js";
import { get, includes, property, isNumber, isUndefined } from "lodash";

export default class SourceForm extends React.Component {
  constructor() {
    super();
    this.state = {
      collapsedSections: false,
    };
  }
  render() {
    const {
      clientId,
      formId,
      resolutionCaseId,
      sourceForm,
      indentationLevel,
      activeSourceForm,
      searchString,
      parentForceVisible,
      addFormMultiple,
    } = this.props;

    const mainFormIsActive =
      get(activeSourceForm, "formId") ===
      (sourceForm.meta.formId || sourceForm.formId);
    const multiFormIsActive = isMultiFormActive(
      activeSourceForm,
      sourceForm,
      formId
    );

    const multiples = sourceForm.meta.multiples;

    let isActive =
      activeSourceForm && // active sourceForm is present
      (multiples ? multiFormIsActive && mainFormIsActive : mainFormIsActive);

    const hideIfNotSearched = !parentForceVisible && !isActive;

    const activeSubForm = get(activeSourceForm, "sections", []).find(
      (form) =>
        form.type === "source-form" &&
        includes(
          window.location.href,
          get(form, "meta.multiples")
            ? `${form.formId}:${form.index || 0}`
            : form.formId
        )
    );

    return sourceForm.visible ? (
      <div data-testid="source-form">
        <MenuLink
          link={getUrl(clientId, resolutionCaseId, sourceForm, formId)}
          onClick={handleClick.bind(this, activeSubForm)}
          name={sourceForm.name}
          icon="cps-icon-forms"
          text={sourceForm.name}
          indentationLevel={indentationLevel}
          bold={isActive}
          searchString={searchString}
          multiples={multiples}
          addFormMultiple={this.addMultiple}
          hideIfNotSearched={hideIfNotSearched}
        />
        {isActive &&
          activeSourceForm &&
          activeSourceForm.sections &&
          !this.state.collapsedSections &&
          activeSourceForm.sections
            .filter(property("visible"))
            .map((section, index) =>
              section.type === "source-form" ? (
                <SourceForm
                  key={section.formId + index}
                  {...this.props}
                  formId={`${
                    multiples ? formId + ":" + sourceForm.index : formId
                  }-${
                    section.meta.multiples
                      ? section.formId + ":" + (section.index || 0)
                      : section.formId
                  }`}
                  activeSourceForm={activeSubForm}
                  sourceForm={section}
                  indentationLevel={indentationLevel + 1}
                  parentForceVisible={true}
                />
              ) : (
                <MenuLink
                  key={isUndefined(section.id) ? section.meta.id : section.id}
                  link={getUrl(
                    clientId,
                    resolutionCaseId,
                    sourceForm,
                    formId,
                    section
                  )}
                  icon={"cps-icon-statements"}
                  text={section.name}
                  indentationLevel={indentationLevel + 1}
                  bold={sectionIsActive(
                    formId,
                    index,
                    section,
                    sourceForm.index
                  )}
                />
              )
            )}
      </div>
    ) : null;
  }
  addMultiple = (e) => {
    e.preventDefault();

    this.props.addFormMultiple(
      getFormIdFromPath(this.props.formId),
      this.props.sourceForm.index
    );
  };
}

function isMultiFormActive(activeSourceForm, sourceForm, formId) {
  if (!sourceForm.meta.multiples) return false;

  const formIndex = sourceForm.index;

  return isNumber(formIndex)
    ? formIndex === get(activeSourceForm, "index")
    : false;
}

function getFormIdFromPath(path) {
  return includes(path, ":") ? path.substring(0, path.lastIndexOf(":")) : path;
}

function getUrl(
  clientId,
  resolutionCaseId,
  sourceForm,
  formId,
  section = { id: 0 }
) {
  return isNumber(sourceForm.index)
    ? `#/forms/clients/${clientId}/resolution-cases/${resolutionCaseId}/sourceforms/${
        getFormIdFromPath(formId) + ":" + sourceForm.index
      }/sections/${section.id}`
    : `#/forms/clients/${clientId}/resolution-cases/${resolutionCaseId}/sourceforms/${formId}/sections/${section.id}`;
}

function handleClick(activeSubForm = {}, evt) {
  if (
    get(this, "props.activeSourceForm.formId") ===
      this.props.sourceForm.meta.formId &&
    activeSubForm.formId === this.props.sourceForm.formId &&
    get(this, "props.activeSourceForm.index") === this.props.sourceForm.index
  ) {
    evt.preventDefault();
    this.setState((prevState) => ({
      collapsedSections: !prevState.collapsedSections,
    }));
  }
}

function sectionIsActive(formId, index, section, formIndex) {
  return (
    includes(window.location.hash, `${formId}/sections/${section.id}`) ||
    (includes(window.location.hash, `${formId}/sections/0`) && index === 0) ||
    includes(
      window.location.hash,
      `${formId}:${formIndex}/sections/${section.id}`
    ) ||
    (includes(window.location.hash, `${formId}:${formIndex}/sections/0`) &&
      index === 0)
  );
}
