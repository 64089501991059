import React from "react";
import { getResolutionCaseObservable } from "./resolution_cases.resource.js";
import { asyncStacktrace, catchSyncStacktrace } from "auto-trace";

export default class FetchResolutionCase extends React.Component {
  constructor() {
    super();
    this.state = {
      resolutionCase: null,
    };
    this.boundCheckFetchResolutionCase = checkFetchResolutionCase.bind(this);
  }
  componentDidMount() {
    this.boundCheckFetchResolutionCase(this.props);
  }
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.boundCheckFetchResolutionCase(this.props);
    }
  }
  render() {
    return (
      <div style={{ height: "100%" }} data-testid="fetch-resolution-case">
        {this.props.children({ resolutionCase: this.state.resolutionCase })}
      </div>
    );
  }
  componentWillUnmount() {
    if (this.resolutionCaseSubscription) {
      this.resolutionCaseSubscription.unsubscribe();
    }
  }
}

function checkFetchResolutionCase(props) {
  // This is to prevent race condition with single-spa:routing-event from with-url-params.component.js
  if (!props.resolutionCaseId || !props.clientId) return;

  if (props.newUrlParams) {
    if (this.resolutionCaseSubscription) {
      this.resolutionCaseSubscription.unsubscribe();
    }

    this.resolutionCaseSubscription = getResolutionCaseObservable(
      props.clientId,
      props.resolutionCaseId
    ).subscribe(
      (resolutionCase) => this.setState({ resolutionCase }),
      asyncStacktrace((err) => {
        if (err.status === 404 || err.status === 403) {
          console.info(
            `/api/clients/{ID}/resolution_cases/{ID} returned ${err.status}, redirecting`
          );
          history.replaceState("", "", `/#/${err.status}`);
        } else catchSyncStacktrace(err);
      })
    );
  }
}
