// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!./menu-item.styles.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu-link-styles__root--_pSJ8 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.menu-link-styles__icon--ozz2f {
  padding-left: 10px;
  color: var(--cps-color-secondary-text);
}

.menu-link-styles__icon--ozz2f:first-child {
  padding-left: 24px;
}

.menu-link-styles__title--FUFMX {
  padding: 0 14px 0 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}

.menu-link-styles__bullet--V0jEV {
  color: var(--cps-color-secondary-text);
  padding-left: 10px;
}

.menu-link-styles__bullet--V0jEV:first-child {
  padding-left: 33px;
}

.menu-link-styles__bold--hyB7C {
  font-weight: bold;
}

.menu-link-styles__add--mfuUp {
  opacity: 0;
  margin-right: 8px;
}

.menu-link-styles__root--_pSJ8:hover .menu-link-styles__add--mfuUp {
  opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/common/menu-link.styles.css"],"names":[],"mappings":"AAAA;EAEE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,sCAAsC;AACxC;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,sCAAsC;EACtC,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,UAAU;EACV,iBAAiB;AACnB;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".root {\n  composes: menuItem from \"./menu-item.styles.css\";\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: center;\n  position: relative;\n}\n\n.icon {\n  padding-left: 10px;\n  color: var(--cps-color-secondary-text);\n}\n\n.icon:first-child {\n  padding-left: 24px;\n}\n\n.title {\n  padding: 0 14px 0 16px;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  overflow: hidden;\n  width: 100%;\n}\n\n.bullet {\n  color: var(--cps-color-secondary-text);\n  padding-left: 10px;\n}\n\n.bullet:first-child {\n  padding-left: 33px;\n}\n\n.bold {\n  font-weight: bold;\n}\n\n.add {\n  opacity: 0;\n  margin-right: 8px;\n}\n\n.root:hover .add {\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `menu-link-styles__root--_pSJ8 ${___CSS_LOADER_ICSS_IMPORT_0___.locals["menuItem"]}`,
	"icon": `menu-link-styles__icon--ozz2f`,
	"title": `menu-link-styles__title--FUFMX`,
	"bullet": `menu-link-styles__bullet--V0jEV`,
	"bold": `menu-link-styles__bold--hyB7C`,
	"add": `menu-link-styles__add--mfuUp`
};
export default ___CSS_LOADER_EXPORT___;
