import React from "react";
import ScrollableTab from "./common/scrollable-tab.component.js";
import ActiveTab from "./common/active-tab.component.js";

export default class Content extends React.Component {
  render() {
    const {
      clientId,
      resolutionCaseId,
      resolutionCase,
      resolutionCaseTitleHeight,
      tabSwitcherHeight,
      smeSwitcherHeight,
      searchHeight,
      searchString,
      expandedFormGroups,
      expandedServices,
      activeTab,
    } = this.props;
    return (
      <ScrollableTab
        resolutionCaseTitleHeight={resolutionCaseTitleHeight}
        tabSwitcherHeight={tabSwitcherHeight}
        smeSwitcherHeight={smeSwitcherHeight}
        searchHeight={searchHeight}
      >
        <ActiveTab {...this.props} />
      </ScrollableTab>
    );
  }
}
