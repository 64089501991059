// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../common/menu-item.styles.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggle-sme-version-styles__root--PtUua {
  background-color: var(--cp-color-nav-secondary-bg);
  display: flex;
  align-items: center;
  padding: 16px 24px;
}
`, "",{"version":3,"sources":["webpack://./src/header/toggle-sme-version.styles.css"],"names":[],"mappings":"AAAA;EAEE,kDAAkD;EAClD,aAAa;EACb,mBAAmB;EACnB,kBAAkB;AACpB","sourcesContent":[".root {\n  composes: menuItemBorder from \"../common/menu-item.styles.css\";\n  background-color: var(--cp-color-nav-secondary-bg);\n  display: flex;\n  align-items: center;\n  padding: 16px 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `toggle-sme-version-styles__root--PtUua ${___CSS_LOADER_ICSS_IMPORT_0___.locals["menuItemBorder"]}`
};
export default ___CSS_LOADER_EXPORT___;
