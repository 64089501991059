import React from "react";

export default class ScrollableTab extends React.Component {
  render() {
    const {
      resolutionCaseTitleHeight,
      tabSwitcherHeight,
      smeSwitcherHeight,
      searchHeight,
    } = this.props;
    const heightAboveTab =
      resolutionCaseTitleHeight +
      tabSwitcherHeight +
      smeSwitcherHeight +
      searchHeight;
    const height = `calc(100% - ${heightAboveTab}px)`;
    return (
      <div
        style={{ overflowY: "auto", maxHeight: height, height }}
        data-testid="scrollable-tab"
      >
        {this.props.children}
      </div>
    );
  }
}
