const charsToEliminate = /[\-\s\(\)]/g;

export function stringMatchesSearch(string, searchString) {
  return searchString ? doSearch(string, searchString) : true;
}

export function getMatch(rawStr, searchString) {
  // For perf, searchString is toLowerCased once at the beginning
  const str = rawStr.toLowerCase();
  let startIndex = str.indexOf(searchString);
  let endIndex = startIndex + searchString.length;

  if (startIndex < 0) {
    let charsRemoved = 0;
    const forgivingString = str.replace(charsToEliminate, (removedStr) => {
      charsRemoved += removedStr.length;
      return "";
    });
    startIndex = forgivingString.indexOf(searchString);

    let unnecessaryCharsRemoved = 0;
    str.slice(0, startIndex).replace(charsToEliminate, (removedStr) => {
      unnecessaryCharsRemoved += removedStr.length;
      return "";
    });
    str
      .slice(startIndex + forgivingString.length)
      .replace(charsToEliminate, (removedStr) => {
        unnecessaryCharsRemoved += removedStr.length;
        return "";
      });

    endIndex =
      startIndex + searchString.length + charsRemoved - unnecessaryCharsRemoved;
  }

  return { startIndex, endIndex };
}

function doSearch(rawStr, searchString) {
  // For perf, searchString is toLowerCased once at the beginning
  const str = rawStr.toLowerCase();
  return (
    str.indexOf(searchString) >= 0 ||
    str.replace(charsToEliminate, "").indexOf(searchString) >= 0
  );
}
