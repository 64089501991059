import React from "react";

export default class WithUrlParams extends React.Component {
  constructor() {
    super();
    this.state = {
      clientId: getClientId(),
      resolutionCaseId: getResolutionCaseId(),
      newUrlParams: true,
    };
    this.boundCheckNewUrlParams = checkNewUrlParams.bind(this);
  }
  componentDidMount() {
    window.addEventListener(
      "single-spa:routing-event",
      this.boundCheckNewUrlParams
    );
    this.setState({ newUrlParams: false });
  }
  render() {
    return (
      <div style={{ height: "100%" }}>
        {this.props.children({ ...this.state })}
      </div>
    );
  }
  componentWillUnmount() {
    window.removeEventListener(
      "single-spa:routing-event",
      this.boundCheckNewUrlParams
    );
  }
}

function checkNewUrlParams() {
  if (
    this.state.clientId !== getClientId() ||
    this.state.resolutionCaseId !== getResolutionCaseId()
  ) {
    this.setState(
      {
        clientId: getClientId(),
        resolutionCaseId: getResolutionCaseId(),
        newUrlParams: true,
      },
      () => {
        this.setState({ newUrlParams: false });
      }
    );
  } else {
    // Even if the url params haven't changed, we need to rerender
    this.setState({ newUrlParams: false });
  }
}

function getResolutionCaseId() {
  const matches = /resolution-cases\/([0-9]+)/.exec(window.location.hash);
  return matches ? Number(matches[1]) : null;
}

function getClientId() {
  const matches = /client[s]?\/([0-9]+)/.exec(window.location.hash);
  return matches ? Number(matches[1]) : null;
}
