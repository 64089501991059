import React from "react";
import { get } from "lodash";
import styles from "./resolution-case-title.styles.css";

export default class ResolutionCaseTitle extends React.Component {
  componentDidMount() {
    if (this.props.newResolutionCaseTitleHeight) {
      this.props.newResolutionCaseTitleHeight(this.el.clientHeight);
    }
  }
  render() {
    return (
      <div className={`${styles.title}`} ref={(el) => (this.el = el)}>
        {get(this.props, "resolutionCase.name")}
      </div>
    );
  }
}
