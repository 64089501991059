import React from "react";
import { catchError } from "auto-trace";
import { CpButton } from "canopy-styleguide!sofe";

import styles from "./service-steps.styles.css";
import AddStep from "./add-step.component.js";
import DeleteStep from "./delete-step.component.js";
import SearchedString from "../common/searched-string.component.js";
import { putResolutionCase } from "../common/resolution_cases.resource.js";

const sectionColors = {
  needs_review: styles.needs_review,
  complete: styles.complete,
};

export default class ServiceSteps extends React.Component {
  constructor() {
    super();
    this.state = {
      addStepModal: false,
      deleteStep: {
        showModal: false,
        id: null,
        name: null,
      },
    };
  }

  getSectionHref = (clientId, resolutionCaseId, programSlug, section) => {
    return section.slug === "analytics"
      ? `#/forms/clients/${clientId}/resolution-cases/${resolutionCaseId}/collections/analytics/offer`
      : `#/taxes/client/${clientId}/resolution-cases/${resolutionCaseId}/program/${programSlug}/section/${
          section.slug || section.id
        }`;
  };

  isActive = (program, section) => {
    if (program.slug === "collections" && section.slug === "analytics") {
      return window.location.href.indexOf("collections/analytics") >= 0;
    } else {
      return (
        window.location.href.indexOf(
          `/program/${program.slug}/section/${section.id}`
        ) >= 0 ||
        window.location.href.indexOf(
          `/program/${program.slug}/section/${section.slug}`
        ) >= 0
      );
    }
  };

  numIconsClass = (section) => {
    return this.isRemovable(section) ? styles.has3Icons : styles.has2Icons;
  };

  canMoveUp = (sectionIndex) => {
    return sectionIndex !== 0;
  };

  canMoveDown = (sectionIndex, program) => {
    return sectionIndex !== program.sections.length - 1;
  };

  isRemovable = (section) => {
    return section.is_removable;
  };

  moveSectionDown = (index) => {
    const { program, clientId, resolutionCaseId, resolutionCase } = this.props;
    const sectionToMove = program.sections[index];
    program.sections[index] = program.sections[index + 1];
    program.sections[index + 1] = sectionToMove;

    putResolutionCase(clientId, resolutionCaseId, resolutionCase).catch((err) =>
      catchError(err)
    );

    /* Once putResolutionCase comes back it will cause a setState in a parent component.
     * This is just optimistic that the putResolutionCase will succeed so that the UI
     * appears faster.
     */
    this.forceUpdate();
  };

  moveSectionUp = (index) => {
    const { program, clientId, resolutionCaseId, resolutionCase } = this.props;
    const sectionToMove = program.sections[index];
    program.sections[index] = program.sections[index - 1];
    program.sections[index - 1] = sectionToMove;

    putResolutionCase(clientId, resolutionCaseId, resolutionCase).catch((err) =>
      catchError(err)
    );

    /* Once putResolutionCase comes back it will cause a setState in a parent component.
     * This is just optimistic that the putResolutionCase will succeed so that the UI
     * appears faster.
     */
    this.forceUpdate();
  };

  render() {
    const { program, clientId, resolutionCaseId, searchString } = this.props;

    return (
      <div className={`${styles.root}`} data-testid="service-steps">
        {program.sections.map((section, index) => (
          <div className={`${styles.step}`} key={section.id} data-testid="step">
            <a
              className={`${styles.stepName} ${
                this.isActive(program, section) ? styles.active : ""
              }`}
              href={this.getSectionHref(
                clientId,
                resolutionCaseId,
                program.slug,
                section
              )}
            >
              <SearchedString
                data-testid="step-text"
                text={section.name}
                searchString={searchString}
                className={sectionColors[section.status] || ""}
              />
            </a>
            <span
              className={`${styles.stepActions} ${this.numIconsClass(section)}`}
            >
              {this.isRemovable(section) && (
                <CpButton
                  data-testid="delete-button"
                  icon="close-circle-open"
                  aria-label="delete step"
                  onClick={() =>
                    this.setState({
                      deleteStep: {
                        showModal: true,
                        id: section.id,
                        name: section.name,
                      },
                    })
                  }
                />
              )}
              <CpButton
                disabled={!this.canMoveUp(index)}
                icon="arrow-open-up"
                aria-label="Move step up"
                onClick={() =>
                  this.canMoveUp(index) && this.moveSectionUp(index)
                }
                data-testid="move-up-button"
              />
              <CpButton
                disabled={!this.canMoveDown(index, program)}
                icon="arrow-open-down"
                aria-label="move step down"
                onClick={() =>
                  this.canMoveDown(index, program) &&
                  this.moveSectionDown(index)
                }
                data-testid="move-down-button"
              />
            </span>
          </div>
        ))}
        <CpButton
          btnType="flat"
          className={styles.addStep}
          onClick={() => {
            this.setState({ addStepModal: true });
          }}
        >
          <span>+ Add step</span>
        </CpButton>
        <AddStep
          show={this.state.addStepModal}
          onClose={() => this.setState({ addStepModal: false })}
          clientId={clientId}
          resolutionCaseId={resolutionCaseId}
          program={program}
        />
        <DeleteStep
          clientId={clientId}
          resolutionCaseId={resolutionCaseId}
          program={program}
          stepInfo={this.state.deleteStep}
          show={this.state.deleteStep.showModal}
          onClose={() =>
            this.setState({
              deleteStep: { showModal: false, id: null, name: null },
            })
          }
        />
      </div>
    );
  }
}
