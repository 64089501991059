import React from "react";
import SearchedString from "./searched-string.component.js";
import styles from "./menu-link.styles.css";
import { noop } from "lodash";
import { stringMatchesSearch } from "../common/search.utils.js";

export default class MenuLink extends React.Component {
  render() {
    const {
      indentationLevel,
      link,
      onClick,
      createNew,
      icon,
      text,
      bold,
      searchString,
      hideIfNotSearched,
      multiples,
      addFormMultiple,
      name,
    } = this.props;
    const bullets = Array.from(Array(indentationLevel || 0));
    if (!stringMatchesSearch(text, searchString) && hideIfNotSearched) {
      return null;
    }

    return (
      <a
        data-name={name}
        className={`${styles.root}`}
        href={link || ""}
        onClick={onClick || noop}
        ref={(el) => (this.el = el)}
        data-testid="menu-link"
      >
        {bullets.map((bullet, index) => (
          <span key={index} className={`${styles.bullet}`}>
            &bull;
          </span>
        ))}
        <i className={`${styles.icon} cps-icon ${icon}`} />
        <span className={`${styles.title} ${bold ? styles.bold : ""}`}>
          <SearchedString text={text} searchString={searchString} />
        </span>
        {multiples && (
          <i
            onClick={addFormMultiple}
            className={`${styles.add} cps-icon cps-icon-quick-add cps-color-primary`}
          />
        )}
      </a>
    );
  }
}
